<template>
  <div class="top">
    <div class="navigation">
      <div class="caidan" @click="dian">
        <img src="../assets/img/la.png" alt="" />
      </div>
      <a href="/?index=1">
        <img src="../assets/img/logo-electron.png" height="40" width="53" alt="" />
      </a>
    </div>
    <!--改正-->
    <div class="navigation-toIng">
      <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
      >
        <template v-for="(ele, index) in mealB">
          <el-menu-item
              :index="String(ele.index)"
              v-if="!ele.child_list"
              :key="index">
            <a
                :href="ele.menu_link + '?id=' + ele.id + '&index=' + ele.index"
                style="height: 70px; display: flex; width: 100%;font-size: 20px;align-items: center;justify-content: center;">
              {{ ele.menu_name }}
            </a>
          </el-menu-item>
          <el-submenu
              :index="String(ele.index)"
              v-if="ele.child_list"
              :key="index"
          >
            <template slot="title">{{ ele.menu_name }}</template>
            <el-menu-item
                v-for="(v, index) in ele.child_list"
                :index="String(ele.id)"
                :key="index"
            >
              <a :href="v.menu_link">{{ v.menu_name }}</a>
            </el-menu-item>
          </el-submenu>
        </template>
      </el-menu>

    </div>
    <div
        class="navigation-to"
        :class="!aaa ? 'open' : ''"
    >
      <div class="top" :style="!aaa ? 'display:flex' : 'display:none'">
        <div class="navigation">
          <div class="caidan" @click="dian">
            <img src="../assets/img/la.png" alt="" />
          </div>
          <a href="/?index=1">
            <img src="../assets/img/logo-electron.png" height="40" width="53"  alt="" />
          </a>
        </div>
        <!--改正-->
        <div class="navigation-toIng">
          <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
          >
            <template v-for="(ele, index) in mealB">
              <el-menu-item
                  :index="String(ele.index)"
                  v-if="!ele.child_list"
                  :key="index"
              >
                <a
                    :href="ele.menu_link + '?id=' + ele.id + '&index=' + ele.index"
                    style="height: 70px; display: flex; width: 100%;font-size: 20px;align-items: center;justify-content: center;">
                  {{ ele.menu_name }}
                </a>
              </el-menu-item>
              <el-submenu
                  :index="String(ele.index)"
                  v-if="ele.child_list"
                  :key="index"
              >
                <template slot="title">{{ ele.menu_name }}</template>
                <el-menu-item
                    v-for="(v, index) in ele.child_list"
                    :index="String(ele.id)"
                    :key="index"
                >
                  <a :href="v.menu_link">{{ v.menu_name }}</a>
                </el-menu-item>
              </el-submenu>
            </template>
          </el-menu>
        </div>
      </div>
      <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
      >
        <template v-for="(ele, index) in mealB">
          <el-menu-item
              :index="String(ele.index)"
              v-if="!ele.child_list"
              :key="index"
          >
            <a
                :href="ele.menu_link + '?id=' + ele.id + '&index=' + ele.index"
                style="height: 70px; display: flex; width: 100%;align-items: center;justify-content: center;">
              {{ ele.menu_name }}
            </a>
          </el-menu-item>
          <el-submenu
              :index="String(ele.index)"
              v-if="ele.child_list"
              :key="index"
          >
            <template slot="title">{{ ele.menu_name }}</template>
            <el-menu-item
                v-for="(v, index) in ele.child_list"
                :index="String(ele.id)"
                :key="index"
            >
              <a :href="v.menu_link">{{ v.menu_name }}</a>
            </el-menu-item>
          </el-submenu>
        </template>
      </el-menu>
    </div>
    <div class="logo">
      <div class="right-logo">
        <a :href="this.logo.redirect_link"
        ><img :src="logo2" height="36" width="100"
        /></a>
      </div>
      <div class="language">
        <div>
          <img src="../img/Header/language.png" height="24" width="24" />
        </div>
        <div>
          <el-menu class="el-menu-demo" mode="horizontal">
            <el-submenu index="2">
              <template slot="title" >
                <a v-show="this.$cookies.get('Language') != 'en_US'"
                >日本語</a>
                <a v-show="this.$cookies.get('Language') == 'en_US'"
                >English</a>
              </template>
              <el-menu-item index="10-1" @click="Jp()">日本語</el-menu-item>
              <el-menu-item index="10-2" @click="En()">English</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/css/style.css';
import API from '@/utils/api';
export default {
  name: "HeaderMenu",
  data() {
    return  {
      routerName: '/',
      users: [],
      activeIndex: this.$route.query.index,
      isCollapse: true,
      logo: {},
      logo2:'',
      imageIP:'http://133.167.41.143:7350',
      // imageIP:'http://124.71.159.123:7350',
      // imageIP:'http://49.212.160.220:7350',
      mealB: [],
      aaa: true,
    }
  },
  mounted() {
    if(window.outerWidth>1050){
      this.aaa = true
    }
  },
  methods: {
    Jp() {
      this.$cookies.set('Language', 'ja_JP', 3600 * 24)
      location.reload();
    },
    En() {
      this.$cookies.set('Language', 'en_US', 3600 * 24);
      location.reload();
    },
    dian() {
      this.aaa = !this.aaa;
    },
    GetMenuList() {
      // 获取用户列表
      API.header_menu(
          {},
          { headers: { 'Accept-Language': this.$cookies.get('Language') } }
      )
          .then((res) => {
            this.mealB = res.data.data.menu_header;
            this.logo = res.data.data.logo;
            this.logo2 = this.imageIP + res.data.data.logo.img_path;
            // this.activeIndex = String(res.data.data.menu_header[0].id);
          })
          .catch((error) => {
            // 处理错误，例如显示错误信息
            console.error(error);
          });
    },
  },
  created() {
    if (this.$cookies.get('Language') === null){
      this.$cookies.set('Language', 'ja_JP', 3600 * 24)
    }
    this.activeIndex = this.$route.query.index
    this.GetMenuList();
    // this.header_menu()
  },
}
</script>

<style lang="scss">
#app {
  .el-menu--horizontal .el-menu .el-menu-item:hover {
    text-decoration: underline;
  }
  .el-menu--horizontal .el-menu .el-menu-item.is-active {
    text-decoration: underline;
  }
  .el-menu--popup {
    padding: 5px 10px;
  }
  .el-submenu is-active {
    height: 70px !important;
  }

  .el-menu--horizontal > .el-submenu .el-submenu__title {
    height: 70px !important;
    display: flex;
    align-items: center;
  }

  .el-menu--horizontal > .el-menu-item {
    height: 70px !important;
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: none !important;
    background-image: url('../img/Header/cheak.png');
    background-size: 43px 5px;
    background-position: center top;
    background-repeat: no-repeat;
  }

  .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    border-bottom: none !important;
    background-image: url('../img/Header/cheak.png');
    background-size: 43px 5px;
    background-position: center top;
    background-repeat: no-repeat;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: none !important;
  }
  .el-menu-item {
    padding: 0 0 !important;
    margin: 0 20px !important;
  }
  .el-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.top {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1999;
  position: fixed;
  background-color: white;
}
.navigation {
  //   width: 15%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-to {
  width: 70%;
  height: 70px;
}

.navigation-toIng {
  width: 70%;
  height: 70px;
  display: block;
}

.logo {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 20px;
}
.caidan {
  display: none;
}
.caidan img:hover {
  display: block;
}
span {
  word-wrap:normal ;
}
a {
  word-wrap:normal ;
}
p{
  word-wrap:normal ;
}
@media screen and (max-width: 820px){
  .right-logo{
    display: none;
  }
  .language{
    margin: 0px;
  }
}

</style>
