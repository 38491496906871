<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import axios from 'axios';
// import API from './utils/api';
// import './assets/css/style.css';
export default {
  data() {
    return {
    };
  },
  created() {

  }

};
</script>

