<template>
  <!-- // eslint-disable-next-line vue/no-parsing-error -->
  <div v-if="render"
       :class="this.Window <= 1550 ? '' : 'fullPage'"
       :ref="this.Window <= 1550 ? '' : 'fullPage'"
  >
    <HeaderMenu></HeaderMenu>
    <div
        class="fullPageContainer"
        ref="fullPageContainer"
        @mousewheel="mouseWheelHandle"
        @DOMMouseScroll="mouseWheelHandle"
        @touchstart="handleTouchstart"
        @touchend="handleTouchend"
        @touchmove="handleTouchmove"
    >
      <div class="img-banner">
        <el-carousel :interval="5000" class="banner">
          <el-carousel-item v-for="item in imgs" :key="item.id">
            <h3
                class="small"
                :style="{
                'background-image': 'url(' + item.img_path + ')',
              }"
            ></h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="introduce">
        <div class="classification-title">
          <img src="../assets/img/红蓝切换.gif" alt="" />
          <span class="tltle">
            {{ this.product.title }}
          </span>
        </div>
        <div style="display: flex"  class="flexone">
          <div style="width: 864px">
            <div class="introduce-imgs">
              <img :src="this.product.img_path" alt="" />
            </div>
          </div>
          <div class="brief">
            <!--          <img src="../assets/img/红蓝切换.gif" alt="" />-->
            <!--          <p class="brief-introduction">-->
            <!--            &lt;!&ndash;                        企業ポリシ&ndash;&gt;-->
            <!--            {{ this.product.title_name }}-->
            <!--          </p>-->
            <p class="paragraph" v-html="this.product.summary">
              <!--            {{  }}-->
            </p>
            <a class="btn" @click="OpenPDF()">
              {{ this.product.button_text }}
              <!--                        詳細はこちら-->
            </a>
          </div>
        </div>
      </div>

      <div class="introduces">
        <div class="briefs">
          <img src="../assets/img/红蓝切换.gif" alt="" />
          <p class="brief-introduction">
            {{ this.product.title }}
          </p>
          <p class="paragraph" v-html="this.product.summary">
          </p>
          <a class="btn" @click="OpenPDF()">
            {{ this.product.button_text }}
          </a>
        </div>
        <div class="change">
          <div class="zuo">
            <div class="introduce-img"></div>
          </div>
          <div class="introduce-imgs">
            <img :src="this.product.img_path" alt="" />
          </div>
        </div>
      </div>
      <div class="classification">
        <div class="classification-title">
          <img src="../assets/img/红蓝切换.gif" alt="" />
          <span class="tltle">
            {{ this.trade.title_name }}
            <!--            营業品目-->
          </span>
        </div>
        <p>
          <!--                    — 経験豊富なので、お選びください —-->
          {{ this.trade.second_title_name }}
        </p>
        <div style="align-items: center">
          <div class="big-box">
            <div class="img-box">
              <div
                  class="show-box"
                  :style="{
                  transform: 'translateX(' + translate + 'px)',
                  transition: tsion ? 'all 0.5s' : 'none',
                }"
              >
                <div
                    class="show-box-box"
                    v-for="(item, index) in trade_list"
                    :key="index"
                >
                  <div class="show-box-img">
                    <img class="box-img" :src="item.img_path" :style="active" />
                    <a
                        :href="
                        item.redirect_link +
                        '?id=' +
                        item.id + '&index=' + '1'
                      "
                    >
                      <div class="mask">
                        <img class="cha1" src="../assets/img/cha1.png" alt="" />
                        <img class="cha" src="../assets/img/cha.png" alt="" />
                        <div
                            style="
                            font-size: 18px;
                            float: right;
                            font-weight: bold;
                            color: #ffffff;
                            padding-top: 170px;
                            padding-right: 20px;
                            width: 94%;
    text-align: right;
                          "
                        >
                          <!-- {{ img_title }} -->
                                                    {{ item.summary }}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
                class="arrowhead-box"
                ref="swiper"
                style="display: flex; justify-content: center"
            >
              <span @click="last"
              ><img
                  src="../assets/img/left.png"
                  alt=""
                  style="height: 50px; width: 50px"
              /></span>
              <div style="width: 50px"></div>
              <span @click="nexts"
              ><img
                  src="../assets/img/right.png"
                  alt=""
                  style="height: 50px; width: 50px"
              /></span>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-title">
          <img src="../assets/img/红蓝切换.gif" alt="" />
          <span class="tltle">
            {{ this.business.title_name }}
            <!--            事業内容-->
          </span>
        </div>
        <div
            style="display: flex; justify-content: center; align-items: center"
        >
          <div>
            <div class="Career" style="margin-top: 52px">
              <!-- margin-left: 268px; -->
              <a
                  :href="this.toLink + '?id=' + this.business.menu_id + '&display_order=' + this.business_list1_display_order + '&index=' + this.index +'#img'+this.business_list1_display_order"
                  class="tu-one"
                  :style="{ backgroundImage: 'url(' + businessImg1 + ')' }"
              >
<!--                <div class="tu-one-bg">-->
<!--                  {{ this.business_list1 }}-->
<!--                  &lt;!&ndash;                                    計測機器・制御機器設計、開発及び製造事業&ndash;&gt;-->
<!--                </div>-->
              </a>
            </div>
            <div class="Career" style="margin-top: 232px">
              <!-- margin-left: 233px; -->
              <a
                  :href="this.toLink + '?id=' + this.business.menu_id + '&display_order=' + this.business_list3_display_order + '&index=' + this.index +'#img'+this.business_list3_display_order"
                  class="tu-three"
                  :style="{ backgroundImage: 'url(' + businessImg3 + ')' }"
              >
<!--                <div class="tu-three-bg">-->
<!--                  {{ this.business_list3 }}-->
<!--                  &lt;!&ndash;                                    生産管理・品質管理システム設計、制作事業&ndash;&gt;-->
<!--                </div>-->
              </a>
            </div>
          </div>
          <div
              class="branch"
              style="display: flex; justify-content: center; align-items: center"
          >
            <div
                class="circle"
                style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
<!--              <div class="core">-->
<!--                <img src="../assets/img/Content.png" alt="" />-->
<!--              </div>-->
            </div>
          </div>
          <div>
            <div class="Career">
              <a
                  :href="this.toLink + '?id=' + this.business.menu_id + '&display_order=' + this.business_list2_display_order + '&index=' + this.index +'#img'+this.business_list2_display_order"
                  class="tu-two"
                  :style="{ backgroundImage: 'url(' + businessImg2 + ')' }"
              >
<!--                <div class="tu-two-bg">-->
<!--                  {{ this.business_list2 }}-->
<!--                  &lt;!&ndash;                                    ソフトウェア・ハードウェア設計開発事業&ndash;&gt;-->
<!--                </div>-->
                <!-- <img src="../assets/img/tu2.png" alt=""> -->
              </a>
            </div>
            <div class="Career" style="margin-top: 268px">
              <!-- margin-left: 20px -->
              <a
                  :href="this.toLink + '?id=' + this.business.menu_id + '&display_order=' + this.business_list4_display_order + '&index=' + this.index +'#img'+this.business_list4_display_order"
                  class="tu-for"
                  :style="{ backgroundImage: 'url(' + businessImg4 + ')' }"
              >
<!--                <div class="tu-for-bg">-->
<!--                  {{ this.business_list4 }}-->
<!--                  &lt;!&ndash;                                    造管設備・機械部品などの輸出事業&ndash;&gt;-->
<!--                </div>-->
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="teo">
        <div class="content-title">
          <img src="../assets/img/红蓝切换.gif" alt="" />
          <span class="tltle">
            {{ this.business.title_name }}
            <!--            事業内容-->
          </span>
        </div>
        <div class="branch">
          <div class="circle">
<!--            <div class="core">-->
<!--              <img src="../assets/img/Content.png" alt="" />-->
<!--            </div>-->
          </div>
        </div>
        <div>
          <div>
            <div class="Careers">
              <a
                  :href="this.toLink + '?id=' + this.business.menu_id + '&display_order=' + this.business_list1_display_order + '&index=' + this.index +'#img'+this.business_list1_display_order"
                  class="tu-one"
                  :style="{ backgroundImage: 'url(' + businessImg1 + ')' }"
              >
<!--                <div class="tu-one-bg">-->
<!--                  {{ this.business_list1 }}-->
<!--                  &lt;!&ndash;                                    計測機器・制御機器設計、開発及び製造事業&ndash;&gt;-->
<!--                </div>-->
              </a>
            </div>
            <div class="Careers">
              <a
                  :href="this.toLink + '?id=' + this.business.menu_id + '&display_order=' + this.business_list3_display_order + '&index=' + this.index +'#img'+this.business_list3_display_order"
                  class="tu-three"
                  :style="{ backgroundImage: 'url(' + businessImg3 + ')' }"
              >
<!--                <div class="tu-three-bg">-->
<!--                  {{ this.business_list3 }}-->
<!--                  &lt;!&ndash;                                    生産管理・品質管理システム設計、制作事業&ndash;&gt;-->
<!--                </div>-->
              </a>
            </div>
          </div>
          <div class="Careers">
            <a
                :href="this.toLink + '?id=' + this.business.menu_id + '&display_order=' + this.business_list2_display_order + '&index=' + this.index +'#img'+this.business_list2_display_order"
                class="tu-two"
                :style="{ backgroundImage: 'url(' + businessImg2 + ')' }"
            >
<!--              <div class="tu-two-bg">-->
<!--                {{ this.business_list2 }}-->
<!--                &lt;!&ndash;                                    ソフトウェア・ハードウェア設計開発事業&ndash;&gt;-->
<!--              </div>-->
              <!-- <img src="../assets/img/tu2.png" alt=""> -->
            </a>
          </div>
          <div class="Careers">
            <a
                :href="this.toLink + '?id=' + this.business.menu_id + '&display_order=' + this.business_list4_display_order + '&index=' + this.index +'#img'+this.business_list4_display_order"
                class="tu-for"
                :style="{ backgroundImage: 'url(' + businessImg4 + ')' }"
            >
<!--              <div class="tu-for-bg">-->
<!--                {{ this.business_list4 }}-->
<!--                &lt;!&ndash;                                    造管設備・機械部品などの輸出事業&ndash;&gt;-->
<!--              </div>-->
            </a>
          </div>
        </div>
      </div>

      <div class="address">
        <div class="address-title">
          <img src="../assets/img/红蓝切换.gif" alt="" />
          <span class="tltle">
            {{ contract.title_name }}
            <!--            問い合わせ-->
          </span>
        </div>
        <div style="display: flex; justify-content: space-evenly" class="ditu">
          <div class="cap">
            <img :src="this.contractImg" alt="" />
          </div>
          <div class="dituone">
            <div class="cap-name" v-for="item in contract_list" :key="item.id">
              <p class="name">
                {{ item.second_title_name }}
                <!--                                E-MAIL：-->
              </p>
              <p class="classname" v-html="item.content">
                <!--                {{ item.content }}-->
                <!--                                a-okuhara@sigma-systems.co.jp-->
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Fdown></Fdown>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderMenu from "../components/Header.vue";
import '../assets/css/style.css';
import Fdown from '../components/Fdown.vue';
// eslint-disable-next-line no-unused-vars
import API from '../utils/api';
export default {
  // name: "Home",
  // eslint-disable-next-line vue/no-unused-components
  components: { Fdown,HeaderMenu }, // 注册组件（局部注册）
  data() {
    return {
      boxwidth: '',
      Window: window.outerWidth,
      menu_id: this.$route.query.id,
      toLink: '',
      business_list1: '',
      business_list2: '',
      business_list3: '',
      business_list4: '',
      detail: '',
      product: {},
      trade: {},
      contract_list: [],
      contract: {},
      trade_list: [],
      businessImg1: '',
      businessImg2: '',
      businessImg3: '',
      businessImg4: '',
      contractImg:'',
      business_list1_display_order:'',
      business_list2_display_order:'',
      business_list3_display_order:'',
      business_list4_display_order:'',
      render: true ,
      business: {},
      index:'',
      imgs: [],
      active: '',
      fullpage: {
        current: 1, // 当前的页面编号
        isScrolling: false, // 是否在滚动,是为了防止滚动多页，需要通过一个变量来控制是否滚动
        deltaY: 0, // 返回鼠标滚轮的垂直滚动量，保存的鼠标滚动事件的deleteY,用来判断是往下还是往上滚
      },
      imageIP:'http://133.167.41.143:7350',
      // imageIP:'http://124.71.159.123:7350',
      // imageIP:'http://49.212.160.220:7350',
      startTime: undefined, // 记录触摸开始的时间
      startX: undefined, // 记录触摸开始的X坐标，本次主要实现的是上下滑动，所以当前坐标不做强制要求
      startY: undefined, // 记录触摸开始的Y坐标
      img: '',
      translateX: 0,
      tsion: true,
      // move:\\
    };
  },
  methods: {
    // resizeFunc() {
    //   // eslint-disable-next-line no-undef
    //   const m = detectZoom();
    //   document.body.style.zoom = 100 / Number(m);
    // },
    OpenPDF() {
      window.open('http://133.167.41.143:7350'+ this.product.pdf_path )
    },
    reload() {
      // 重新渲染
      this.render = false
      this.$nextTick(() => {
        this.render = true
      })
      location.reload()
    },
    paragraphTo() {
      this.$emit('getSon', this.$route.query.id);
    },

    // 往下切换
    next() {
      if (window.outerWidth <= 1550) {
        return;
      }
      let len = 5; // 页面的个数
      if (this.fullpage.current + 1 <= len) {
        // 如果当前页面编号+1 小于总个数，则可以执行向下滑动
        this.fullpage.current += 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
      }
    },
    // 往上切换
    pre() {
      if (window.outerWidth <= 1550) {
        return;
      }
      if (this.fullpage.current - 1 > 0) {
        // 如果当前页面编号-1 大于0，则可以执行向下滑动
        this.fullpage.current -= 1; // 页面+1
        this.move(this.fullpage.current); // 执行切换
      }
    },
    // 滚动事件
    move(index) {
      if (window.outerWidth <= 1550) {
        return;
      }
      this.fullpage.isScrolling = true; // 为了防止滚动多页，需要通过一个变量来控制是否滚动
      this.directToMove(index); //执行滚动
      setTimeout(() => {
        //这里的动画是1s执行完，使用setTimeout延迟1s后解锁
        this.fullpage.isScrolling = false;
      }, 1010);
    },
    // 执行滚动
    directToMove(index) {
      if (window.outerWidth <= 1550) {
        return;
      }
      let height =
          this.$refs['fullPage'] != undefined
              ? this.$refs['fullPage'].clientHeight
              : 0; //获取屏幕的宽度
      let scrollPage = this.$refs['fullPageContainer']; // 获取执行tarnsform的元素
      let scrollHeight; // 计算滚动的告诉，是往上滚还往下滚
      scrollHeight = -(index - 1) * height + 'px';
      scrollPage.style.transform = `translateY(${scrollHeight})`;
      this.fullpage.current = index;
    },
    // 监听鼠标监听
    mouseWheelHandle(event) {
      if (window.outerWidth <= 1550) {
        return;
      }
      // 添加冒泡阻止
      let evt = event || window.event;
      if (evt.stopPropagation) {
        evt.stopPropagation();
      } else {
        evt.returnValue = false;
      }
      if (this.fullpage.isScrolling) {
        // 判断是否可以滚动
        return false;
      }
      let e = event.originalEvent || event;
      this.fullpage.deltaY = e.deltaY || e.detail; // Firefox使用detail
      if (this.fullpage.deltaY > 0) {
        this.next();
      } else if (this.fullpage.deltaY < 0) {
        this.pre();
      }
    },
    // 清除触摸事件
    handleTouchmove(event) {
      if (window.outerWidth <= 1550) {
        return;
      }
      event.preventDefault();
    },
    //手指按下屏幕
    handleTouchstart(event) {
      if (window.outerWidth <= 1550) {
        return;
      }
      this.startTime = Date.now();
      this.startX = event.changedTouches[0].clientX;
      this.startY = event.changedTouches[0].clientY;
    },
    //手指离开屏幕
    handleTouchend(event) {
      if (window.outerWidth <= 1550) {
        return;
      }
      const endTime = Date.now();
      // const endX = event.changedTouches[0].clientX
      const endY = event.changedTouches[0].clientY;
      //判断按下的时长
      if (endTime - this.startTime > 2000) {
        return;
      }
      //滑动的方向
      let direction = '';
      //先判断用户滑动的距离，是否合法，合法:判断滑动的方向 注意 距离要加上绝对值
      if (Math.abs(endY - this.startY) > 10) {
        //滑动方向
        direction = endY - this.startY > 0 ? 'down' : 'up';
      } else {
        return;
      }
      //用户做了合法的滑动操作
      // console.log('方向'+direction)
      if (direction === 'up') {
        this.next();
      }
      if (direction === 'down') {
        this.pre();
      }
    },

    last() {
      this.translateX--;
      this.tsion = true;

      if (window.outerWidth < 1910 && window.outerWidth > 1525 && this.translateX < 0) {
        setTimeout(() => {
          this.tsion = false;
          this.translateX = this.trade_list.length - 4;
        }, 500);
      }else {
        if (window.outerWidth < 1525 && window.outerWidth > 1140 && this.translateX < 0) {
          setTimeout(() => {
            this.tsion = false;
            this.translateX = this.trade_list.length - 3;
          }, 500);
        }else {
          if (window.outerWidth < 1140 && window.outerWidth > 755 && this.translateX < 0) {
            setTimeout(() => {
              this.tsion = false;
              this.translateX = this.trade_list.length - 2;
            }, 500);
          }else {
            if (window.outerWidth < 755 && window.outerWidth > 20 && this.translateX < 0) {
              setTimeout(() => {
                this.tsion = false;
                this.translateX = this.trade_list.length - 1;
              }, 500);
            }else {
              if (window.outerWidth > 1910 && this.translateX < 0) {
                setTimeout(() => {
                  this.tsion = false;
                  this.translateX = this.trade_list.length - 5;
                }, 500);
              }
            }
          }
        }
      }
    },
    nexts() {
      this.translateX++;
      this.tsion = true;
      if (window.outerWidth > 1910 && this.translateX > this.trade_list.length - 5) {
        setTimeout(() => {
          this.tsion = false;
          this.translateX = 0;
        }, 500);
      }
      if (window.outerWidth < 1910 && window.outerWidth > 1525 && this.translateX > this.trade_list.length - 4) {
        setTimeout(() => {
          this.tsion = false;
          this.translateX = 0;
        }, 500);
      }
      if (window.outerWidth < 1525 && window.outerWidth > 1140 && this.translateX > this.trade_list.length - 3) {
        setTimeout(() => {
          this.tsion = false;
          this.translateX = 0;
        }, 500);
      }
      if (window.outerWidth < 1140 && window.outerWidth > 755 && this.translateX > this.trade_list.length - 2) {
        setTimeout(() => {
          this.tsion = false;
          this.translateX = 0;
        }, 500);
      }
      if (window.outerWidth < 755 && this.translateX > this.trade_list.length - 1) {
        setTimeout(() => {
          this.tsion = false;
          this.translateX = 0;
        }, 500);
      }
    },
    getIndexList() {
      // 获取用户列表
      API.createUser(
          {},
          { headers: { 'Accept-Language': this.$cookies.get('Language') } }
      )
          .then((res) => {
            this.imgs = res.data.data.rotation_chart_list;
            for(var v=0;v < this.imgs.length;v++ ){
              this.imgs[v].img_path =  this.imageIP + res.data.data.rotation_chart_list[v].img_path
            }
            this.product = res.data.data.product;
            this.product.img_path = this.imageIP + res.data.data.product.img_path;
            this.trade = res.data.data.trade;
            this.trade_list = res.data.data.trade.trade_list;
            for(var i=0;i < this.trade_list.length;i++ ){
              this.trade_list[i].img_path =  this.imageIP + res.data.data.trade.trade_list[i].img_path
            }
            this.business = res.data.data.business;
            this.toLink = res.data.data.business.business_list[0].redirect_link;
            this.business_list1 =
                res.data.data.business.business_list[0].img_title;
            this.business_list2 =
                res.data.data.business.business_list[1].img_title;
            this.business_list3 =
                res.data.data.business.business_list[2].img_title;
            this.business_list4 =
                res.data.data.business.business_list[3].img_title;
            this.businessImg1 = this.imageIP + res.data.data.business.business_list[0].img_path;
            this.businessImg2 = this.imageIP + res.data.data.business.business_list[1].img_path;
            this.businessImg3 = this.imageIP + res.data.data.business.business_list[2].img_path;
            this.businessImg4 = this.imageIP + res.data.data.business.business_list[3].img_path;
            this.contract_list = res.data.data.contract.contract_list;
            this.contract = res.data.data.contract;
            this.contractImg = this.imageIP + res.data.data.contract.img_path;
            this.business_list1_display_order = res.data.data.business.business_list[0].display_order;
            this.business_list2_display_order = res.data.data.business.business_list[1].display_order;
            this.business_list3_display_order = res.data.data.business.business_list[2].display_order;
            this.business_list4_display_order = res.data.data.business.business_list[3].display_order;
            this.index = res.data.data.business.display_order
          })
          .catch((error) => {
            // 处理错误，例如显示错误信息
            console.error(error);
          });
    },
  },
  mounted() {
    if(window.outerWidth>1600){
      window.addEventListener('resize', this.reload)
    }
    // this.Window = window.innerWidth
    // console.log(this.Window)
    // if (window.innerWidth>=1550){
    //   location.reload();
    //   return
    // }
    // if (window.innerWidth<1550){
    //   location.reload();
    //   return
    // }
    // this.Window()
    // window.onresize = () => {
    //   return (() => {
    //     if (window.outerWidth <= 1550) {
    //       window.scrollTo(0, 0);
    //       location.reload();
    //       return;
    //     } else {
    //       window.scrollTo(0, 0);
    //       location.reload();
    //       return;
    //     }
    //   })();
    // };
    // this.resizeFunc()
    // window.onresize = () => {
    //   this.resizeFunc()
    // }
  },
  beforeDestroy() {
    if(window.outerWidth>1600){
      window.removeEventListener('resize', this.reload)
    }
  },
  created() {
    this.getIndexList();
    this.paragraphTo();
  },
  computed: {
    translate() {
      if (window.outerWidth > 1910 && this.translateX <= this.trade_list.length - 5){
        return -this.translateX * 357.1;
      }else {
        if (window.outerWidth < 1910 && window.outerWidth > 1525 && this.translateX <= this.trade_list.length - 4) {
          return -this.translateX * 357.1;
        } else {
          if (window.outerWidth < 1525 && window.outerWidth > 1140  && this.translateX <= this.trade_list.length - 3) {
            return -this.translateX * 357.1;
          } else {
            if (window.outerWidth < 1140 && window.outerWidth > 755  && this.translateX <= this.trade_list.length - 2) {
              return -this.translateX * 357.1;
            } else {
              if (window.outerWidth < 755 && this.translateX <= this.trade_list.length - 1) {
                return -this.translateX * 357.1;
              } else {
                return 0;
              }
            }
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.change {
  width: 864px;
}

.el-carousel__item h3 {
  display: flex !important;
  align-items: flex-end;
}

/deep/.el-carousel__arrow {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0!important;
  height: 80px !important;
  width: 80px !important;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  border: 6px solid #fff !important;
  color: #fff;
  position: absolute;
  /*top: 86% !important;*/
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 30px !important;
}

.Career {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  /*第一个linear-gradient表示内填充渐变色，第二个linear-gradient表示边框渐变色*/
  /*background-image:linear-gradient(180deg, #c6d6f5 0%, #73a3ff 80%),*/
  /*linear-gradient(180deg, #c6d6f5 0%, #73a3ff 80%);*/
  border: 3px solid transparent;
  border-radius: 10px;
  /*border-image-source: linear-gradient(to right, #c69cf7, #bed0f3);*/
  border-image-slice: 1;
  overflow: hidden;
  /* width: 350px; */
  width: 18.5vw!important;
  /* height: 200px; */
  height: 10vw!important;
}

.fullPage {
  height: 100vh;
  overflow: hidden;
}
.Careers a {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  /*第一个linear-gradient表示内填充渐变色，第二个linear-gradient表示边框渐变色*/
  /*background-image: linear-gradient(180deg, #c69cf7 0%, #bed0f3 80%),*/
  /*linear-gradient(#c69cf7, #bed0f3);*/
  border: 3px solid transparent;
  /*border-radius: 10px;*/
  /*border-image-source: linear-gradient(to bottom, #c6d6f5 0%, #73a3ff 100%);*/
  border-image-slice: 1;
}
.fullPageContainer {
  width: 100%;
  height: 100vh;
  transition: all linear 0.5s;
}

.el-carousel__indicators {
  display: none !important;
}

/deep/.el-carousel__arrow--left {
  display: none;
}

/deep/.el-carousel__arrow {
  /* display: none; */
  margin-top: 0 !important;
  margin-right: 0 !important;
  z-index: 999999;
}
.el-form-item__content {
  width: 100%;
}

span {
  margin: 0;
  padding: 0;
}

.big-box {
  width: 100%;
  height: 560px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*position: absolute;*/
  margin-top: 5vw;
}

.img-box {
  overflow: hidden;
  height: 480px;
  width: 93%;
}

.show-box {
  display: flex;
  height: 100%;
  width: 20%;
  transition: all 0.5s;
}

.show-box-box {
  margin: 0 10px 0 10px;
  background-size: 100%;
  float: left;
  width: 337.1px;
  height: 480px;
  transition: all 0.5s;
}

.show-box-img {
  overflow: hidden;
  width: 337.1px;
  height: 480px;
  position: relative;
}

.cha1 {
  margin-top: 214px;
  margin-left: 144px;
  width: 49px;
  height: 49px;
}

.cha {
  margin-top: 214px;
  margin-left: 144px;
  width: 49px;
  height: 49px;
  display: none;
}

.show-box-img:hover .box-img {
  transform: scale(1.1);
}

.show-box-img:hover .cha1 {
  display: none;
}

.show-box-img:hover .cha {
  display: block;
}

.box-img {
  width: 337.1px;
  height: 480px;
  position: relative;
  transition: all 1s;
}

.mask {
  width: 337.1px;
  height: 480px;
  background: #000000;
  opacity: 0.5;
  z-index: 99;
  position: absolute;
  top: 0;
  transition: all 1s;
}

.arrowhead-box {
  /* position: absolute; */
  top: 40%;
  float: left;
  width: 100%;
  height: 50px;
  text-align: center;
  margin-top: 30px;
}

.arrowhead-box span {
  height: 60px;
  border-radius: 50px;
  cursor: pointer;
  opacity: 0.5;
}

.arrowhead-box span:nth-child(2) {
  transform: rotate(180deg);
}

.arrowhead-box span:hover {
  opacity: 1;
}

.swiper-boxs {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-boxs span {
  float: left;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50px;
  margin-left: 10px;
  cursor: pointer;
  transition: all 0.5s;
}

.swiper-boxs span:nth-child(1) {
  width: 100px;
}

/deep/.el-carousel__container {
  height: 93vh !important;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.small {
  width: 100%;
  height: 93vh;
  background-size: 100% 100%;
}

.page-turning {
  width: 56px;
  height: 56px;
  padding-top: 82.7vh;
  margin-left: 93.5vw;
}

.introduce {
  width: 100%;
  height: 100vh;
  background-image: url(../assets/img/enterprise.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  /* background-size: 100%; */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.introduces {
  width: 100%;
  height: 100vh;
  background-image: url(../assets/img/enterprise.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  /* background-size: 100%; */
  position: relative;
  /* display: flex; */
}


.introduce-imgs {
  /* width: 542px;
    height: 362px; */
  position: absolute;
  top: 34vh;
  left: 6vw;
  transition-delay: 0s;
}

.introduce-imgs img {
  width: 612px;
  height: 416px;
  /* transition-delay:7s */
  transition-property: all;
  /*所有属性都获得效果*/
  transition-duration: 0.5s;
}

.introduce-imgs:hover img {
  transition-property: all;
  /*所有属性都获得效果*/
  transition-duration: 0.5s;
  /*规定完成过渡效果需要花费的时间*/
  transition-timing-function: linear;
  /*规定以相同速度开始至结束的过渡效果*/
  transition-delay: 0s;
  /* 定义过渡效果何时开始*/
  transform: translate(-3.5vw, 2vw);
  /* transform: translateX(60px); */
}

.brief-introduction {
  width: 200px;
  height: 48px;
  font-size: 40px;
  font-family: Source Han Serif SC;
  font-weight: bold;
  font-style: italic;
  color: #092241;
  margin: 32px 0 0 0;
}

.brief {
  margin: 3vw 11vw;
}

.brief img {
  margin-top: 132px;
}

.paragraph {
  font-size: 16px;
  max-width: 80vw;
  font-weight: 400;
  color: #231815;
  line-height: 28px;
  margin-top: 38px;
  overflow: hidden;
}

.btn {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  width: 120px;
  height: 46px;
  background: #d52425;
  border-radius: 8px;
  line-height: 46px;
  text-align: center;
  margin-top: 51px;
}

.btn:hover {
  color: #d52425;
  background: #fff;
  border: 2px solid #d52425;
  width: 120px;
  height: 44px;
  margin-top: 49px;
  cursor: pointer;
}

.character {
  width: 670px;
  height: 118px;
  background: #ffffff;
  border: 1px solid #092241;
  border-radius: 8px;
  margin-top: 44px;
  display: flex;
}

.character-name {
  height: 51px;
  margin: 33px 0 0 16%;
}

.character-name .position {
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #231815;
}

.character-name .name {
  height: 19px;
  font-size: 18px;
  font-weight: bold;
  color: #231815;
  margin-top: 18px;
}

.classification {
  width: 100%;
  height: 100vh;
  background-image: url(../assets/img/classification.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.classification-title {
  text-align: center;
  padding-top: 2vw!important;
}

.content-title {
  text-align: center;
  padding-top: 3%;
}

.classification-title img {
  margin-bottom: 16px;
}

.tltle {
  font-size: 40px;
  font-weight: bold;
  font-style: italic;
  color: #092241;
  margin-left: 19px;
}

.content {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* kaishi */
.tu-one {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18.5vw!important;
  height: 10vw!important;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  position: relative;
  transition: all 1s;

}

.tu-one:hover {
  transform: scale(1.1);
}

.tu-three {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  width: 18.5vw!important;
  height: 10vw!important;
  background-image: url(../assets/img/tu3.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  transition: all 1s;
}

.tu-three:hover {
  transform: scale(1.1);
}

.tu-two {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  width: 18.5vw!important;
  height: 10vw!important;
  background-image: url(../assets/img/tu2.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  transition: all 1s;
}

.tu-two:hover {
  transform: scale(1.1);
}

.branch {
  margin-top: 24px;
  width: 36vw!important;
  height: 30vw!important;
  background-image: url(../assets/img/branch.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}

.circle {
  width: 30vw!important;
  height: 30vw!important;
  background-image: url(../assets/img/careerContent.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100%;
}

/*.core {*/
/*  width: 19vw!important;*/
/*  height: 19vw!important;*/
/*  margin-left: 0!important;*/
/*  padding-top: 0!important;*/
/*}*/

/*.core img {*/
/*  width: 19vw!important;*/
/*}*/

.tu-for {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18.5vw!important;
  height: 10vw!important;
  background-size: 100%;
  background-image: url(../assets/img/tu4.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  transition: all 1s;
}

.tu-for:hover {
  transform: scale(1.1);
}

.tu-one-bg {
  width: 18vw!important;
  background: linear-gradient(
      90deg,
      rgba(27, 86, 159, 0) 0%,
      rgba(27, 86, 159, 0.94) 43%,
      rgba(27, 86, 159, 0) 100%
  );
  font-size: 0.8vw;
  font-family: Source Han Serif CN;
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
  text-align: center;
  line-height: 52px;
  position: absolute;
}

.tu-one-bg a {
  color: #ffffff;
}

.tu-three-bg {
  width: 18vw!important;
  background: linear-gradient(
      90deg,
      rgba(27, 86, 159, 0) 0%,
      rgba(27, 86, 159, 0.94) 43%,
      rgba(27, 86, 159, 0) 100%
  );
  font-size: 0.8vw;
  font-family: Source Han Serif CN;
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
  text-align: center;
  line-height: 52px;
  position: absolute;
}

.tu-three-bg a {
  color: #ffffff;
}

.tu-two-bg {
  width: 18vw!important;
  background: linear-gradient(
      90deg,
      rgba(27, 86, 159, 0) 0%,
      rgba(27, 86, 159, 0.94) 43%,
      rgba(27, 86, 159, 0) 100%
  );
  font-size: 0.8vw;
  font-family: Source Han Serif CN;
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
  text-align: center;
  line-height: 52px;
  position: absolute;
}

.tu-two-bg a {
  color: #ffffff;
}

.tu-for-bg {
  width: 18vw!important;
  background: linear-gradient(
      90deg,
      rgba(27, 86, 159, 0) 0%,
      rgba(27, 86, 159, 0.94) 43%,
      rgba(27, 86, 159, 0) 100%
  );
  font-size: 0.8vw;
  font-family: Source Han Serif CN;
  font-weight: bold;
  font-style: italic;
  color: #ffffff;
  text-align: center;
  line-height: 52px;
  position: absolute;
}

.tu-for-bg a {
  color: #ffffff;
}

.address {
  min-height: 77vh;
  background: #f9f9f9;
}

.address-title {
  text-align: center;
  padding-top: 122px;
}

.btns {
  width: 1782px;
  height: 6px;
  background: #092241;
  opacity: 0.1;
  border-radius: 3px;
}

.container {
  width: 1782px;
  position: relative;
  overflow: hidden;
}

.container ul {
  display: flex;
  width: 1782px;
  height: 480px;
  overflow: hidden;
  position: relative;
  margin-left: 69px;
}

.container ul li {
  list-style: none;
  height: 100%;
  padding-right: 10px;
}

.container ul li img {
  width: 340px;
  height: 480px;
  background: #000000;
  opacity: 0.5;
}
.cap img {
  width: 710px;
  background: #f5f3ef;
  border-radius: 8px;
  margin-top: 68px;
  margin-left: 16%;
}

.name {
  height: 14px;
  font-size: 18px;
  font-family: Source Han Serif SC;
  font-weight: bold;
  font-style: italic;
  color: #231815;
  flex-shrink: 0;
  width: 130px;
}

.classname {
  /*  */
  /*height: 39px;*/
  font-size: 16px;
  margin: 0 20px;
  font-family: Source Han Sans CN;
  line-height: 24px;
  font-weight: 400;
  font-style: italic;
  color: #231815;
}

.cap-name {
  display: flex;
  margin-top: 30px;
}

.introduces {
  display: none;
}

.teo {
  display: none;
}

.dituone {
  margin-top: 30px;
  margin-left: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* @media screen and (min-width: 1920px){
  .flexone{
    margin-left: 12vw;
  }
  .introduce-imgs{
    left: 22vw;
  }
  .classification{
    background-size: 100%;
  }
} */
@media screen and (max-width: 1360px){
  .big-box{
    margin-top: 1vw !important;
  }
  .introduce {
    height: initial;
  }
  .content-title{
    padding-top:0px !important
  }
  /*.classification-title{*/
  /*  padding-top: 0px !important;*/
  /*}*/
}

@media screen and (max-width: 1000px) {
  .tu-one-bg {
    width: 18vw!important;
    background: linear-gradient(
        90deg,
        rgba(27, 86, 159, 0) 0%,
        rgba(27, 86, 159, 0.94) 43%,
        rgba(27, 86, 159, 0) 100%
    );
    font-size: 0.8vw;
    font-family: Source Han Serif CN;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    text-align: center;
    line-height: 52px;
    position: absolute;
  }

  .tu-one-bg a {
    color: #ffffff;
  }

  .tu-three-bg {
    width: 18vw;
    background: linear-gradient(
        90deg,
        rgba(27, 86, 159, 0) 0%,
        rgba(27, 86, 159, 0.94) 43%,
        rgba(27, 86, 159, 0) 100%
    );
    font-size: 0.8vw;
    font-family: Source Han Serif CN;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    text-align: center;
    line-height: 52px;
    position: absolute;
  }

  .tu-three-bg a {
    color: #ffffff;
  }

  .tu-two-bg {
    width: 18vw;
    background: linear-gradient(
        90deg,
        rgba(27, 86, 159, 0) 0%,
        rgba(27, 86, 159, 0.94) 43%,
        rgba(27, 86, 159, 0) 100%
    );
    font-size: 0.8vw;
    font-family: Source Han Serif CN;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    text-align: center;
    line-height: 52px;
    position: absolute;
  }

  .tu-two-bg a {
    color: #ffffff;
  }

  .tu-for-bg {
    width: 18vw;
    background: linear-gradient(
        90deg,
        rgba(27, 86, 159, 0) 0%,
        rgba(27, 86, 159, 0.94) 43%,
        rgba(27, 86, 159, 0) 100%
    );
    font-size: 0.8vw;
    font-family: Source Han Serif CN;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    text-align: center;
    line-height: 52px;
    position: absolute;
  }

  .tu-for-bg a {
    color: #ffffff;
  }
}

@media screen and (max-width: 1600px) {
  /* 首页最下面的 */
  .dituone {
    margin: 2%;
    margin-left: 90px;
    margin-right: 3vw;
    /* padding-top: 2vh; */
  }
  .cap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cap img {
    width: 100%;
    /* height: 380px; */
    background: #f5f3ef;
    border-radius: 8px;
    margin-top: 68px;
    margin-left: 19%;
  }

  /* 首页图片下边的轮播（第一个） */

  .introduce-imgs img {
    width: 32.5vw;
    height: 23vw;
    /* transition-delay:7s */
    transition-property: all;
    /*所有属性都获得效果*/
    transition-duration: 0.5s;
  }

  .content {
    width: 100%;
    /* height: 100vh; */
    min-height: 75vh;
    background-color: #fff;
  }

  /*.core img {*/
  /*  width: 19vw;*/
  /*}*/
}

@media screen and (max-width: 820px) {
  .classification {
    height: initial;
    padding-bottom: 5vw;
  }
  .address {
    padding-top: 12vw;
  }
  .introduces {
    height: initial;
  }
  .big-box {
    position: relative;
  }
  .content-title {
    margin-top: 12vw!important;
  }
  .address-title {
    padding-top: 0;
  }
  .small {
    height: 100%;
  }
  .classname {
    line-height: inherit!important;
    height: inherit!important;
  }
  /deep/.el-carousel__container {
    height: 48vw !important;
  }

  /deep/.el-carousel__arrow {
    position: absolute;
    width: 50px !important;
    height: 50px !important;
    margin: 0 !important;
    top: 86% !important;
  }

  .teo {
    display: block;
  }

  .introduce {
    display: none;
  }

  .introduces {
    display: block;
  }

  .brief img {
    margin-top: 0;
  }

  .brief {
    margin-left: 15px;
  }

  .zuo {
    display: none;
    padding-top: 92px;
  }

  /* 首页图片下的第一个轮播图 把它们的宽高设置成一样的 */

  .introduce-imgs {
    position: initial!important;
    padding: 20px;
  }
  .introduce-imgs:hover img {
    transform: initial!important;
  }
  /* 首页图片下的第一个轮播图 把它们的宽高设置成一样的 */
  .introduce-imgs img {
    width: 100% !important;
    height: 52vw !important;
  }

  .classification-title {
    padding-top: 12vw !important;
    z-index: 99999;
  }

  .Career {
    display: none;
  }

  .branch {
    background-image: none !important;
  }

  .circle {
    width: 100vw!important;
    height: 100vw!important;
    background-image: url(../assets/img/careerContent.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
    position: relative;
  }

  /*.core {*/
  /*  position: absolute;*/
  /*  top: 0!important;*/
  /*  left: 0!important;*/
  /*  margin-left: 0;*/
  /*  margin-top: 0;*/
  /*  padding-top: 0;*/
  /*  display: flex;*/
  /*  height: 100% !important;*/
  /*  position: absolute;*/
  /*  width: 100% !important;*/
  /*  align-items: center;*/
  /*  justify-content: center;*/
  /*}*/

  /*.core img {*/
  /*  width: 49vw!important;*/
  /*}*/

  .content {
    display: none;
  }

  /* 首页（事业内容）改成动态的 */
  .branch {
    width: 100vw!important;
    height: 100vw!important;
  }

  .Careers {
    margin: 12vw 0;
    display: flex;
    justify-content: center;
  }

  .fullPage {
    height: 100vh;
    overflow: unset;
  }

  .cap img {
    /* height: 0; */
    width: 83vw;
    margin: 0 7vw;
  }

  .ditu {
    display: flex;
    flex-direction: column;
  }

  .cap-name {
    display: flex;
    margin-top: 9px;
  }

  .dituone {
    margin-left: 8vw !important;
  }

  /* 改变 */
  .change {
    width: 100vw;
  }

  /* 页面下来第一个轮播图 */
  .briefs {
    text-align: center;
  }

  .brief-introduction {
    display: inline-block;
  }

  .paragraph {
    max-width: 100vw;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .btn {
    margin-left: 5vw;
  }

  /* 事业内容 */

  .tu-one {
    width: 350px!important;
    height: 200px!important;
    /* width: 18.5vw;
  height: 11vw; */
    background-repeat: no-repeat;
    background-position: 0 0;
    transition: all 1s;
  }

  .tu-one:hover {
    transform: scale(1.1);
  }

  .tu-three {
    width: 350px!important;
    height: 200px!important;
    /* width: 18.5vw;
  height: 11vw; */
    background-image: url(../assets/img/tu3.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    transition: all 1s;
  }

  .tu-three:hover {
    transform: scale(1.1);
  }

  .tu-two {
    width: 350px!important;
    height: 200px!important;
    /* width: 18.5vw;
  height: 11vw; */
    background-image: url(../assets/img/tu2.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    transition: all 1s;
  }

  .tu-two:hover {
    transform: scale(1.1);
  }

  .tu-for {
    width: 350px!important;
    height: 200px!important;
    /* width: 18.5vw;
  height: 11vw; */
    background-image: url(../assets/img/tu4.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    transition: all 1s;
  }

  .tu-for:hover {
    transform: scale(1.1);
  }

  .tu-one-bg {
    width: 342px!important;
    height: 51px!important;
    background: linear-gradient(
        90deg,
        rgba(27, 86, 159, 0) 0%,
        rgba(27, 86, 159, 0.94) 43%,
        rgba(27, 86, 159, 0) 100%
    );
    font-size: 16px;
    font-family: Source Han Serif CN;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    text-align: center;
    line-height: 52px;
    position: absolute;
  }

  .tu-one-bg a {
    color: #ffffff;
  }

  .tu-three-bg {
    width: 342px!important;
    height: 51px!important;
    background: linear-gradient(
        90deg,
        rgba(27, 86, 159, 0) 0%,
        rgba(27, 86, 159, 0.94) 43%,
        rgba(27, 86, 159, 0) 100%
    );
    font-size: 16px;
    font-family: Source Han Serif CN;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    text-align: center;
    line-height: 52px;
    position: absolute;
  }

  .tu-three-bg a {
    color: #ffffff;
  }

  .tu-two-bg {
    width: 342px!important;
    height: 51px!important;
    background: linear-gradient(
        90deg,
        rgba(27, 86, 159, 0) 0%,
        rgba(27, 86, 159, 0.94) 43%,
        rgba(27, 86, 159, 0) 100%
    );
    font-size: 16px;
    font-family: Source Han Serif CN;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    text-align: center;
    line-height: 52px;
    position: absolute;
  }

  .tu-two-bg a {
    color: #ffffff;
  }

  .tu-for-bg {
    width: 342px!important;
    height: 51px!important;
    background: linear-gradient(
        90deg,
        rgba(27, 86, 159, 0) 0%,
        rgba(27, 86, 159, 0.94) 43%,
        rgba(27, 86, 159, 0) 100%
    );
    font-size: 16px;
    font-family: Source Han Serif CN;
    font-weight: bold;
    font-style: italic;
    color: #ffffff;
    text-align: center;
    line-height: 52px;
    position: absolute;
  }

  .tu-for-bg a {
    color: #ffffff;
  }
}
</style>
