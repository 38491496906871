<template>
  <div class="footer">
    <div>
      <img src="../assets/img/logo-electron.png" height="40" width="53" />
    </div>
    <div>
      <div>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-for="(item, index) in footer" :key="index">
            <a :href="item.menu_link + '?id=' + item.id + '&index='+ item.display_order">{{
              item.menu_name
            }}</a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div>
        Copyright 2006 SIGMA Software Systems Co.,Ltd. All rights reserved.
      </div>
    </div>
  </div>
  <!-- <div class="tail">
    <div class="tail-next">
      <div class="tail-logo">
        <img src="../img/Footer/footerLogo.png" height="40" width="182" />
      </div>
      <div class="classification-name">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item v-for="(item, index) in footer" :key="index">
            <a :href="item.menu_link + '?id=' + item.id">{{
              item.menu_name
            }}</a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="parti">
        Copyright 2006 SIGMA Software Systems Co.,Ltd. All rights reserved.
      </div>
    </div>
    <div class="footerRight">
      <span class="particulars"
        >Copyright 2006 SIGMA Software Systems Co.,Ltd. All rights
        reserved.</span
      >
    </div>
  </div> -->
</template>

<script>
import API from '@/utils/api';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Fdown',
  props: {
    // msg: String
  },
  data() {
    return {
      footer: {},
    };
  },
  methods: {
    footer_menu() {
      // 获取用户列表
      API.footer_menu({},
          {headers:{'Accept-Language' : this.$cookies.get('Language')}})
        .then((res) => {
          this.footer = res.data.data.menu_footer;
          // console.log(this.footer,'3333');
          // 处理获取到的用户列表，例如更新 UI
          // console.log(users);
        })
        .catch((error) => {
          // 处理错误，例如显示错误信息
          console.error(error);
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.footer_menu();
  },
};
</script>

<style scoped lang="scss">
.el-breadcrumb__item:last-child .el-breadcrumb__inner a {
  color: #ffffff;
  cursor: pointer !important;
}
.el-breadcrumb__item {
  font: initial;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover a {
  color: #409eff;
  cursor: pointer !important;
  text-decoration: underline;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner a {
  font-weight: 700;
}
.el-breadcrumb__inner a {
  color: #ffffff;
}

.el-breadcrumb__inner:hover a {
  text-decoration: underline;
}

.footerRight {
  width: 40%;
  padding-right: 4%;
  padding-top: 100px;
  background-image: url('../img/Footer/footer.png');
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 670px, 23vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
