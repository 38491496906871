import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from "axios";
import VueCookies from 'vue-cookies'
import VueScrollTo from 'vue-scrollto';

let scrollOptions = {
  container: "body", //滚动的容器
  duration: 500, //滚动时间
  easing: "ease", //缓动类型
  offset: -50, // 滚动时应应用的偏移量。此选项接受回调函数
  force: true, // 是否应执行滚动
  cancelable: true, // 用户是否可以取消滚动
  onStart: false, // 滚动开始时的钩子函数
  onDone: false, // 滚动结束时候的钩子函数
  onCancel: false, // 用户取消滚动的钩子函数
  x: false, // 是否要在x轴上也滚动
  y: true    // 是否要在y轴上滚动
};

Vue.use(VueScrollTo, scrollOptions);
Vue.use(VueCookies)
// import "amfe-flexible/index"
// eslint-disable-next-line no-unused-vars
// import Service from '@vue/cli-service'
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.use(ElementUI);

axios.defaults.baseURL = 'http://localhost:8017';
// axios.defaults.baseURL = 'http://49.212.160.220:7350';
axios.defaults.timeout = 5000;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

