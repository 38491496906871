import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import companyView from '../views/companyView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/companyView',
    name: 'companyView',
    component:  () => import(/* webpackChunkName: "about" */ '../views/companyView.vue')
  },
  {
    path: '/EmploymentInformationView',
    name: 'EmploymentInformationView',
    component: () => import(/* webpackChunkName: "about" */ '../views/EmploymentInformationView.vue')
  },
  {
    path: '/BusinessContentsView',
    name: 'BusinessContentsView',
    component: () => import(/* webpackChunkName: "about" */ '../views/BusinessContentsView.vue')
  },
  {
    path: '/BusinessItemsView',
    name: 'BusinessItemsView',
    component: () => import(/* webpackChunkName: "about" */ '../views/BusinessItemsView.vue')
  },
  // {
  //   path: '/CorporateNewsView',
  //   name: 'CorporateNewsView',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/CorporateNewsView.vue')
  // },
  // {
  //   path: '/NewsDetailView',
  //   name: 'NewsDetailView',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/NewsDetailView.vue')
  // },
  {
    path: '/HandlingGoods',
    name: 'HandlingGoods',
    component: () => import(/* webpackChunkName: "about" */ '../views/HandlingGoods.vue')
  },
  {
    path: '/SuitView',
    name: 'SuitView',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuitView.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
