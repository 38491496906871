// 前端代码示例（使用 Axios）

import axios from 'axios';
const apiClient = axios.create({
  // baseURL: 'http://192.168.2.163:8020/',
  // baseURL: 'http://124.71.159.123:7350/',
  baseURL: 'http://133.167.41.143:7350/',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default {
//   getUsers() {
//     return apiClient.get('rotationChart/get_index_data_list');
//   },
  createUser(data,config) {
    return apiClient.post('rotationChart/get_index_data_list',data,config);
  },
  header_menu(data,config) {
    return apiClient.post('menu/get_header_menu_list',data,config);
  },
  footer_menu(data,config) {
    return apiClient.post('menu/get_footer_menu_list',data,config);
  },
  getEmpioy(data,config) {
    return apiClient.post('employmentInformation/get_employment_information',data,config);
  },
  getEmpioyList(data,config) {
    return apiClient.post('label/get_label',data,config);
  },
  get_corporate_news(data,config) {
    return apiClient.post('corporateNews/get_corporate_news',data,config);
  },
  get_corporate_news_detail(data,config) {
    return apiClient.post('corporateNews/get_corporate_news_detail',data,config);
  },
  get_about_us(data,config) {
    return apiClient.post('companySummary/get_about_us',data,config);
  },
  insert_mail(data,config) {
    return apiClient.post('mailTxt/insert_mail',data,config);
  },
  get_banner_img(data,config) {
    return apiClient.post('menu/get_banner_img',data,config);
  },
  get_trade_img(data,config) {
    return apiClient.post('/trade/get_trade_img',data,config);
  },
  insert_mail_inquiry(data,config) {
    return apiClient.post('/mailTxt/insert_mail_inquiry',data,config);
  },
  get_business_detail_image(data,config) {
    return apiClient.post('/business/get_business_detail_image',data,config);
  }

};
// export const detectZoom = () => {
//   let ratio = 0,
//     screen = window.screen,
//     ua = navigator.userAgent.toLowerCase()
//   if (window.devicePixelRatio !== undefined) {
//     ratio = window.devicePixelRatio
//   } else if (~ua.indexOf('msie')) {
//     if (screen.deviceXDPI && screen.logicalXDPI) {
//       ratio = screen.deviceXDPI / screen.logicalXDPI
//     }
//   } else if (
//     window.outerWidth !== undefined &&
//     window.innerWidth !== undefined
//   ) {
//     ratio = window.outerWidth / window.innerWidth
//   }
//   if (ratio) {
//     ratio = Math.round(ratio * 100)
//   }
//   return ratio
// }
